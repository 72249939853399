import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from "../../components/project_page/ProjectPageWrapper"
import TextBlock from "../../components/project_page/TextBlock"
import Mobile3 from "../../components/project_page/Mobile3"
import Web1 from "../../components/project_page/Web1"
import Image1 from "../../components/project_page/Image1"
import ImageGrid from "../../components/project_page/ImageGrid"

import globalStyles from "../global.module.css"

const headerBackground = "#eaeaea"
const navColor = "#222"
const Cassette = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="Cassette"
      link={null}
      oneLiner="The first ever Audio Q&A app."
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Question"
          headline={`Have you ever wondered what it would look like if Twitter, Soundcloud and r/AskReddit had a baby?`}
          content={`Video is not for everyone. People have things to say,
            but don’t want to put their face on YouTube.
            Reading someone’s answer on an r/AskReddit thread is different
            than hearing it in their own voice. It’s raw, real, and more interesting.
          `}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <TextBlock
          title="The Product"
          headline={`No self-promotion. Just real answers to real questions.`}
          content={`A mobile app to record your answers to funny, serious
            and thought-provoking questions, and listen to answers from people
            in their own voice.`}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Mobile3
          one={<Img fluid={data.body1.childImageSharp.fluid} />}
          two={<Img fluid={data.body2.childImageSharp.fluid} />}
          three={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <div
          className={`${globalStyles.flex} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}
        >
          <div
            className={`${globalStyles.flexGrow} ${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}
          >
            <TextBlock
              title="Engineering"
              headline={`Ride the wave(form)`}
              content={`We built a custom processing pipeline to compress audio and
                convert it into waveform visualizations.`}
            />
          </div>
          <div
            className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}
          >
            <Image1 img={<Img fluid={data.body5.childImageSharp.fluid} />} />
          </div>
        </div>
      </div>
      <div className={`${globalStyles.marginBottomHuge}`}>
        <Web1
          maxWidth={800}
          color={headerBackground}
          desktopImage={<Img fluid={data.body4.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body4.childImageSharp.fluid} />}
        />
      </div>
    </ProjectPageWrapper>
  </Layout>
)

export default Cassette

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/cassette/header.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileHeader: file(
      relativePath: { eq: "projects/cassette/header-mobile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body1: file(relativePath: { eq: "projects/cassette/app1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body2: file(relativePath: { eq: "projects/cassette/app2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body3: file(relativePath: { eq: "projects/cassette/app3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body4: file(relativePath: { eq: "projects/cassette/web1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body5: file(relativePath: { eq: "projects/cassette/Waves.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
